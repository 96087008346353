<style scoped>
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}

.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}

.rent-address-proof-row:hover {
  background-color: rgba(169, 169, 169, 0.147);
}

.requested-tag {
  margin-left: 120px;
}
</style>
<template>
  <div>
    <div class="tab_header d-flex black--text font-weight-bold px-2 mb-1">
      <div
        style="width: 75%"
        class="d-flex align-center py-3"
        @click="toggleAccordion()"
      >
        <div class="text-body-1 font-weight-medium px-3">
          Rent Address Proof
        </div>
        <span
          class="text-body-2 requested-tag font-weight-bold secondary_2--text"
          v-if="is_address_proof_required"
        >
          <v-icon class="secondary_2--text mr-1">
            mdi-alert-circle-outline </v-icon
          >Requested
        </span>
      </div>
      <div style="width: 25%" class="d-flex align-center justify-end">
        <v-btn
          outlined
          dense
          small
          color="success"
          class="font-weight-bold mr-2"
          @click="filterDocumentStatus"
          :disabled="isBtnDisabled"
          >Verify All</v-btn
        >
        <v-btn
          small
          outlined
          color="secondary_2"
          class="font-weight-bold"
          :disabled="is_address_proof_required || loader"
          :loading="request_btn_loader || loader"
          @click="openRequestAgainReasonDialog()"
        >
          Request Again
        </v-btn>
        <v-btn
          fab
          text
          x-small
          class="mx-2"
          outlined
          depressed
          v-if="getPermission('Refresh Request')"
          :disabled="!is_address_proof_required"
          @click="openResetConfirmationDialog()"
        >
          <v-icon color="black">
            mdi-reload
          </v-icon>
        </v-btn>
        <v-icon
          color="black"
          class="mx-3"
          v-if="show"
          @click="toggleAccordion()"
        >
          mdi-chevron-up
        </v-icon>
        <v-icon color="black" class="mx-3" v-else @click="toggleAccordion()">
          mdi-chevron-down
        </v-icon>
      </div>
    </div>
    <div v-if="show" class="py-2">
      <DivLoader
        v-if="loader"
        :loading_type="'list-item-two-line, list-item-two-line'"
      ></DivLoader>
      <template v-else>
        <template v-if="address_proof[0]">
          <v-row
            class="rent-address-proof-row ma-0 px-3"
            v-for="(item, index) in address_proof"
            :key="item.id"
          >
            <v-col cols="4" class="d-flex align-center">
              <p class="field-content mr-2">{{ index + 1 }}.</p>
              <div>
                <p class="field-content">
                  {{
                    item.document_type
                      .replace(/_/g, " ")
                      .toLowerCase()
                      .split(" ")
                      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      .join(" ")
                  }}
                </p>
                <p class="error--text text-caption">
                  {{ item.rejection_reason }}
                </p>
              </div>
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-center">
              <!-- {{ item.document_status }} -->
              <span
                class="text-body-2 ml-2 font-weight-bold success--text"
                v-if="item.document_status == 'approved'"
              >
                <v-icon class="success--text mr-1">
                  mdi-check-circle-outline </v-icon
                >Verified
              </span>
              <span
                class="text-body-2 ml-2 font-weight-bold secondary_2--text"
                v-else-if="item.document_status == 'requested'"
                ><v-icon class="secondary_2--text mr-1">
                  mdi-alert-circle-outline </v-icon
                >Requested
              </span>
              <span
                v-else-if="item.document_status == 'rejected'"
                class="text-body-2 ml-2 font-weight-bold error--text"
              >
                <v-icon class="error--text mr-1">
                  mdi-close-circle-outline</v-icon
                >Rejected
              </span>
              <span v-else> </span>
            </v-col>
            <v-col cols="5" class="d-flex align-center justify-end">
              <div
                class="d-flex"
                v-if="
                  item.document_status != 'approved' &&
                    item.document_status != 'rejected'
                "
              >
                <v-btn
                  small
                  outlined
                  color="success"
                  class="font-weight-bold ml-2"
                  v-if="item.document_status != 'requested'"
                  @click="updateDocumentStatus(item.id, 'approved')"
                  :disabled="btn_loader"
                  :loading="btn_loader"
                >
                  Verify
                </v-btn>
                <v-btn
                  small
                  outlined
                  color="error"
                  class="font-weight-bold ml-2"
                  v-if="item.document_status != 'requested'"
                  :disabled="btn_loader"
                  :loading="btn_loader"
                  @click="openRejectionReasonDialog(item)"
                >
                  <!-- @click="updateDocumentStatus(item.id, 'rejected')" -->
                  Reject
                </v-btn>
              </div>
              <v-btn
                small
                text
                @click="
                  isImageDocument(item.document)
                    ? previewImage(item.document, item.document_type)
                    : viewDocument(item.document)
                "
                class="indigo lighten-5 py-4 ml-2"
              >
                <v-icon color="deep-purple darken-4">mdi-eye-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <!-- <p class="text-center font-weight-bold">No Documents Available</p> -->
          <p v-if="aadhaar_address_same_as_current_address" class="text-center">
            Current Address is same as
            <span class="font-weight-black">Aadhaar</span>
          </p>
          <p v-else-if="current_resident_type == 'Own'" class="text-center">
            Current Resident Type is
            <span class="font-weight-black">{{ current_resident_type }}</span>
          </p>
          <p v-else class="text-center font-weight-bold">
            No Documents Available
          </p>
          <!-- <p class="text-center font-weight-bold">{{current_resident_type}}</p> -->
        </template>
      </template>
    </div>
    <RejectionReasonDialog
      v-if="rejection_reason_dialog_data.flag"
      :dialog="rejection_reason_dialog_data"
      @recallAddressDetails="getRentaddressProof()"
    />
    <RequestAgainReasonDialog
      v-if="request_again_reason_dialog_data.flag"
      :dialog="request_again_reason_dialog_data"
      @recallAddressDetails="getRentaddressProof()"
    />
    <ResetConfirmationDialog
      ref="reset_confirmation_dialog"
      @refresh="getRentaddressProof()"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      show: true,
      address_proof: [],
      loader: false,
      request_btn_loader: false,
      btn_loader: false,
      // document_id: null,
      is_address_proof_required: false,
      rejection_reason_dialog_data: {
        flag: false,
        reject_pre_verification: false,
        document_id: null,
        document_type: "",
      },
      request_again_reason_dialog_data: {
        flag: false,
        document_type: "",
      },
      current_resident_type: "",
      permanent_resident_type: "",
      aadhaar_address_same_as_current_address: false,
      uploadedDocs: [],
      isBtnDisabled: false,
    };
  },
  components: {
    DivLoader: () => import("@/components/ui/DivLoader"),
    RejectionReasonDialog: () =>
      import("@/components/pre-verification/dialog/RejectionReasonDialog"),
    RequestAgainReasonDialog: () =>
      import("@/components/pre-verification/dialog/RequestAgainReasonDialog"),
    ResetConfirmationDialog: () =>
      import("@/components/pre-verification/dialog/ResetConfirmation"),
  },
  computed: {
    ...mapGetters({
      customer_id: "pre_verification/getCustomerId",
    }),
  },
  mounted() {
    this.getRentaddressProof();
  },
  methods: {
    openRejectionReasonDialog(value) {
      this.rejection_reason_dialog_data.flag = true;
      this.rejection_reason_dialog_data.reject_pre_verification = false;
      this.rejection_reason_dialog_data.document_id = value.id;
      this.rejection_reason_dialog_data.document_type = value.document_type;
    },
    toggleAccordion() {
      this.show = !this.show;
      if (this.show) {
        this.getRentaddressProof();
      }
    },
    // view document
    viewDocument(url) {
      window.open(url, "_blank");
    },

    // returning true if image extension is png, jpg or, jpeg
    isImageDocument(url) {
      let ext = url.split(".");
      ext = ext[ext.length - 1];
      return ext == "png" || ext == "jpg" || ext == "jpeg" || ext == "webp";
    },
    // if isImageDocument returns true then opens image in new tab
    previewImage(url, document_name) {
      let image_url = url;
      let formated_document_name = document_name
        .replace(/_/g, " ")
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");

      let route = this.$router.resolve({
        name: "view-image",
        params: {
          image: this.encript(image_url),
          document: this.encript(formated_document_name),
        },
      });
      window.open(route.href, "_blank");
    },
    getRentaddressProof() {
      const self = this;
      self.loader = true;
      let params = {
        customer_id: this.customer_id,
      };

      const successHandler = (response) => {
        if (response.data.success) {
          self.address_proof = response.data.application_form.address_proof;
          self.is_address_proof_required =
            response.data.application_form.is_address_proof_required;
          // self.document_id = self.address_proof[0].id || null;
          self.current_resident_type =
            response.data.application_form.address_details.current_address.residential_type;
          self.permanent_resident_type =
            response.data.application_form.address_details.permanent_address.residential_type;
          self.aadhaar_address_same_as_current_address =
            response.data.application_form.address_details.current_address.aadhaar_address_same_as_current_address;
          self.documentChecker();
          self.disableVerifyAllBtn();
        }
      };
      const finallyHandler = () => {
        self.loader = false;
      };

      self.request_GET(
        self,
        self.$urls.NEW_CUSTOMER_APPLICATION_FORM,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    documentChecker() {
      let doc = this.address_proof.find(
        (item) => item.document_status === "approved"
      );
      if (doc) {
        this.$store.dispatch("pre_verification/setAddressProofStatus", true);
      } else {
        if (this.aadhaar_address_same_as_current_address) {
          this.$store.dispatch("pre_verification/setAddressProofStatus", true);
        } else if (this.current_resident_type == "Own") {
          this.$store.dispatch("pre_verification/setAddressProofStatus", true);
        } else {
          this.$store.dispatch("pre_verification/setAddressProofStatus", false);
        }
      }

      let doc2 = this.address_proof.find(
        (item) => item.document_status === "uploaded"
      );
      if (doc2) {
        this.$store.dispatch(
          "pre_verification/setAddressProofUploadStatus",
          true
        );
      } else {
        this.$store.dispatch(
          "pre_verification/setAddressProofUploadStatus",
          false
        );
      }
    },
    updateDocumentStatus(document_id, action) {
      const self = this;
      self.btn_loader = true;

      let form = new FormData();
      form.append("document_id", document_id);
      form.append("document_status", action);
      form.append("rejection_reason", "");

      const successHandler = (response) => {
        if (response.data.success) {
          this.getRentaddressProof();
          this.disableVerifyAllBtn();
        }
      };
      const finallyHandler = () => {
        self.btn_loader = false;
      };

      self.request_POST(
        self,
        self.$urls.NEW_CUSTOMER_DOCUMENT_VERIFICAION,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    openRequestAgainReasonDialog() {
      this.request_again_reason_dialog_data.flag = true;
      this.request_again_reason_dialog_data.document_type = "address_proof";
    },

    disableVerifyAllBtn() {
      let count = 0;
      for (let item of this.address_proof) {
        if (
          item.document_status == "approved" ||
          item.document_status == "rejected"
        ) {
          count = count + 1;
        }
      }
      if (count == this.address_proof.length) {
        this.isBtnDisabled = true;
      } else {
        this.isBtnDisabled = false;
      }
    },
    filterDocumentStatus() {
      for (let item of this.address_proof) {
        if (
          item.document_status == "uploaded" ||
          item.document_status !== "rejected"
        ) {
          this.uploadedDocs.push(item.id);
        }
      }
      this.verifyAllRentProofs();
    },
    // verify-all-api
    verifyAllRentProofs() {
      let form = {
        document_id_list: this.uploadedDocs,
        document_status: "approved",
      };
      const successHandler = (response) => {
        this.getRentaddressProof();
        this.isBtnDisabled = true;
      };
      const self = this;
      self.request_POST(
        self,
        self.$urls.NEW_CUSTOMER_DOCUMENT_VERIFICAION,
        form,
        successHandler,
        null,
        null
      );
    },
    openResetConfirmationDialog() {
      this.$refs.reset_confirmation_dialog.openResetConfirmation(
        "address_proof"
      );
    },
  },
};
</script>
